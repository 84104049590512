import Router from 'next/router';
import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { AnalyticsBrowser } from '@june-so/analytics-next';

const fetchUser = async url => {
  const res = await fetch(url);
  const data = await res.json();
  return { user: data?.user || null };
};

export function useUser() {
  const { data, error } = useSWR('/api/user', fetchUser);
  if (error) {
    Router.push('/login');
    return;
  }
  if (!data) return { loading: true };
  return data.user;
}

export function useJune(writeKey) {
  const [analytics, setAnalytics] = useState(undefined);

  useEffect(() => {
    const loadAnalytics = async () => {
      let response = AnalyticsBrowser.load({
        writeKey,
      });
      setAnalytics(response);
    };
    loadAnalytics();
  }, [writeKey]);

  return analytics;
}
