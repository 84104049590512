import { createStore, action } from 'easy-peasy';
import { StoreModel } from './model';

export default createStore<StoreModel>(
  {
    modals: {
      openModal: { type: '', id: '', buildingId: null },
      setOpenModal: action((state, payload) => {
        state.openModal.type = payload.type;
        state.openModal.id = payload.id;
        state.openModal.buildingId = payload.buildingId;
      }),
    },
    view: {
      searchTerm: '',
      setSearchTerm: action((state, payload) => {
        state.searchTerm = payload;
      }),
    },
    notifications: {
      isToastActive: false,
      activeToastType: '',
      activeToastDetails: '',
      setActiveToast: action((state, payload) => {
        state.isToastActive = true;
        state.activeToastType = payload.type;
        state.activeToastDetails = payload.details;
      }),
      closeActiveToast: action((state, payload) => {
        state.isToastActive = false;
      }),
    },
    user: {
      activeUser: '',
      setActiveUser: action((state, payload) => {
        state.activeUser = payload.user;
      }),
    },
    activeBuilding: {
      id: '',
      userRole: '',
      setActiveBuilding: action((state, payload) => {
        state.id = payload;
      }),
      setUserRole: action((state, payload) => {
        state.userRole = payload;
      }),
    },
  },
  // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
);
