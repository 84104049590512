import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import useSWR from 'swr';

import { useJune } from '../lib/hooks';
import { magic } from '../lib/magic';
import { UserContext } from '../lib/UserContext';

const fetchUser = async url => {
  const res = await fetch(url);
  const data = await res.json();
  return { user: data?.user || null };
};

const requestAccessToken = async () => {
  let token;
  const res = await fetch('/api/user');
  if (res.ok) {
    const data = await res.json();
    token = data?.user?.token || null;
  } else {
    token = 'public';
  }
  return token;
};

export function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState('');
  const [userLoading, setUserLoading] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);

  const router = useRouter();
  const analytics = useJune(process.env.NEXT_PUBLIC_JUNE_WRITE_KEY);

  const { data, error } = useSWR('/api/user', fetchUser);

  const publicRoutes = [
    '/',
    '/login',
    '/docs',
    '/terms',
    '/privacy',
    '/guides/homeowners-guide',
    '/guides/maintenance-guide',
  ];
  const isPublicRoute =
    publicRoutes.includes(router.pathname) || router.pathname.startsWith('/p/');

  // Steps to load the user:
  // If user isn't logged in and trying to access non-public page, redirect to '/login'
  // If the user is in the process of logging in, make sure that the /login page doesn't let them login again
  // If user is logged in, make sure the db client is initialized for them
  // If the user is logged in and tries to access /login, then redirect them to the /d
  // If the user is logged in and comes from an external source, redirect them to the right link
  // If the user is not logged in and comes from an external source, let them log in then redirect to the desired destination
  // If the user logs out, redirect them to the /login page

  const login = async ({ didToken, email }) => {
    setUserLoading(true);

    // handle email vs. other methods
    if (!didToken && email) {
      didToken = await magic.auth.loginWithEmailOTP({ email });
    }
    const res = await fetch('/api/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + didToken,
      },
    });

    if (res.status === 200) {
      const userInfo = await magic.user.getInfo();
      console.log('[UserProvider]', userInfo);
      setUser(userInfo);
      const accessToken = await requestAccessToken();
      setToken(accessToken);
      console.log(
        '[UserProvider]: Login successful, redirecting...',
        res.status,
      );
      router.push('/d');
    } else {
      console.error(
        '[UserProvider]: Login unsuccessful, redirecting to login page...',
      );
      router.push('/login');
    }
    setUserLoading(false);
  };

  // check if user is authenticated
  useEffect(() => {
    const checkLoginStatus = async () => {
      console.log('[UserProvider]: checking user status');
      try {
        const loggedIn = await magic.user.isLoggedIn();
        if (loggedIn) {
          console.log('[UserProvider]: user logged in');
          const token = await requestAccessToken();
          setToken(token);
          setUserAuthenticated(true);
          setUserLoading(false);
        }
      } catch (error) {
        console.log('[UserProvider]: user not logged in');
        console.error('[UserProvider] error checking login status', error);
      }
    };

    checkLoginStatus();
  }, []);

  useEffect(() => {
    // TODO: Clean this up and manage with legible user states:
    // If NOT_AUTHENTICATED
    // If UNSUCCESSFULLY_AUTHENTICATED
    // IF AUTHENTICATING
    // IF AUTHENTICATED
    if (!userAuthenticated) {
      return;
    }

    if (data?.user) {
      console.log('[UserProvider]: user retrieved');
      setUser(data.user);
      setUserLoading(false);
      if (analytics) {
        analytics.identify(data.user.issuer, {
          email: data.user.email,
        });
      }
    } else if (data && !data.user && !isPublicRoute) {
      // This might be unnnecessarily logging the user out.
      setUserLoading(false);
      console.log('[UserProvider]: user not retrieved');
      if (typeof window !== 'undefined') {
        router.push('/login');
      }
    } else if (error) {
      setUserLoading(false);
      console.error('[UserProvider]: error', error);
      if (typeof window !== 'undefined') {
        router.push('/login');
      }
    }
  }, [data, error, userAuthenticated]);

  return (
    <UserContext.Provider
      value={{
        login,
        user,
        token,
        userLoading,
        setUser, // used in logout only
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
